<template>
    <div class="p-4 bg-white rounded-2xl shadow-md w-full mx-auto lg:mx-28">
      <h2 class="text-xl font-bold mb-4">Últimos Saques Realizados</h2>
      <div class="relative h-[140px] lg:h-[300px] overflow-hidden">
        <ul class="space-y-2 animate-scroll">
          <li v-for="(withdrawal, index) in sortedWithdrawals" :key="index" class="p-3 border rounded-lg flex justify-between items-center">
            <div>
              <p class="font-medium">{{ censorName(withdrawal.name) }}</p>
              <p class="text-sm text-gray-500">{{ withdrawal.time }}</p>
            </div>
            <p class="font-bold text-green-500">R$ {{ withdrawal.amount.toFixed(2) }}</p>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from "vue";
  
  // Função para censurar o nome do usuário
  function censorName(name) {
    const firstPart = name.slice(0, 2);
    const censoredPart = "*".repeat(name.length - 4);
    const lastPart = name.slice(-2);
    return `${firstPart}${censoredPart}${lastPart}`;
  }
  
  // Função para gerar uma lista de saques aleatórios
  function generateWithdrawals() {
    const names = [
      "joaosilva68",
      "mariasantos22",
      "carlosoliveira",
      "ana_paula92",
      "lucas.souza33",
      "fernandalima",
      "rafaelalmeida",
      "brunasouza99",
      "pedrohenrique",
      "julianamartins",
      "vitorrodrigues",
      "camilaferrreira",
      "andersonpereira",
      "larissasilva44",
      "gustavoalves",
      "patriciagomes",
      "marcoscarvalho",
      "renatadias",
      "eduardocosta",
      "vivianelopes",
      "jessicamouraa",
      "paulogoncalves",
      "andrebarreto",
      "fabianasantos",
      "leonardopires",
      "adrianeferreira",
      "marianacarvalho",
      "henriquealves",
      "biancasilveira",
      "ricardosouza",
      "gabrielmontana",
      "alinefreitas",
      "thiagopereira",
      "lucianagomes",
      "vanessaaraujo",
      "mateusoliveira",
      "carlaalmeida",
      "rodrigomartins",
      "gustavoschmit",
      "flaviarosa",
      "suzanasilva",
      "joaovitor",
      "marceloalmeida",
      "brunacarvalho",
      "renatocosta",
      "tatianemoraes",
      "camilaoliveira",
      "anapaulasantos",
      "felipegoncalves",
      "jorgebastos",
      "robertolima",
      "marcialucas",
      "monicabarbosa",
      "elianealves",
      "raquelsantos",
      "fabiofarias",
      "flavioborges",
      "katiasilva",
      "rogerioramos",
      "maurocarvalho",
      "dianemacedo",
      "cristianaaraujo",
      "sergiogomes",
      "barbaraferreira",
      "lucasgoncalves",
      "tatianapinto",
      "victoralmeida",
      "julianaramos",
      "priscilamonteiro",
      "alessandrasouza",
      "danielpires",
      "daniellylopes",
      "amandabarreto",
      "fernandocosta",
      "isabelarodrigues",
      "diogomonteiro",
      "paulinhacarvalho",
      "caiopereira",
      "leticiafreitas",
      "klebersonsantos",
      "valterdias",
      "matheuscarvalho",
      "simonemartins",
      "wesleyfaria",
      "alexcarvalho",
      "nataliagomes",
      "raianemoura",
      "brendadias",
      "tiagomorais",
      "vivisouza",
      "brunomacedo",
      "juliosilveira",
      "clarissafernandes",
      "michelefreitas",
      "henriquecastro",
      "deboraaraujo",
      "andreasantos",
      "carolinaalves",
      "mauriciosouza"
    ];
  
    const withdrawals = [];
    const now = new Date();
  
    for (let i = 0; i < 100; i++) {
      const randomName = names[Math.floor(Math.random() * names.length)];
      const randomAmount = (Math.random() * 9900 + 100).toFixed(2); // Valores entre 100 e 10000
      const time = new Date(now.getTime() - i * Math.floor(Math.random() * 60 + 1) * 1000); // Alguns segundos atrás
  
      withdrawals.push({
        name: randomName,
        amount: parseFloat(randomAmount),
        timestamp: time, // Salva o objeto Date para ordenação
        time: time.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit", second: "2-digit" }),
      });
    }
  
    return withdrawals;
  }
  
  const withdrawals = ref(generateWithdrawals());
  
  // Lista ordenada cronologicamente (do mais recente para o mais antigo)
  const sortedWithdrawals = computed(() => {
    return withdrawals.value.slice().sort((a, b) => b.timestamp - a.timestamp);
  });
  </script>
  
  <style scoped>
  .bg-white {
    background-color: #ffffff;
  }
  .shadow-md {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .animate-scroll {
    display: flex;
    flex-direction: column;
    animation: scroll 60s linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  </style>
  