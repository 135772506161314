<template>
    <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-blue-500 bg-opacity-75 z-50">
      <div class="bg-white rounded-2xl shadow-2xl w-96 p-6 text-center relative text-sm">
        <h2 class="font-bold mb-4 text-blue-600" v-if="!isError" v-html="currentMessage"></h2>
        <h2 class="font-bold mb-4 text-red-600" v-if="isError" v-html="currentMessage"></h2>
  
        <!-- Progress animation -->
        <div v-if="!isError" class="w-full h-4 bg-gray-200 rounded-full overflow-hidden">
          <div
            class="h-full bg-blue-500 transition-all duration-300 ease-linear"
            :style="{ width: progress + '%' }"
          ></div>
        </div>
  
        <!-- Close button for error -->
        <button
          v-if="isError"
          class="mt-4 px-4 py-2 bg-red-500 text-white font-bold rounded-lg shadow hover:bg-red-600"
          @click="closeModal"
        >
          {{ actionText }}
        </button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, watch } from 'vue';
  
  // Props
  const props = defineProps({
    steps: {
      type: Array,
      required: true,
      default: []
    },
    actionText: {
        default: 'Fechar'
    },
    action: {
        default: ''
    }   
  });
  
  // Reactive state
  const isVisible = ref(true);
  const currentMessage = ref("Solicitando seu saque...");
  const progress = ref(0);
  const isError = ref(false);

  let progressInterval = null;
  
  // Methods
  const startProcess = () => {
    const totalDuration = 9000; // Total duration in ms (3 steps x 3 seconds each)
    const stepDuration = totalDuration / props.steps.length;
  
    // Update progress bar continuously
    const interval = 100; // Update every 100ms
    const increment = 100 / (totalDuration / interval);
    progressInterval = setInterval(() => {
      progress.value += increment;
      if (progress.value >= 100) {
        clearInterval(progressInterval);
      }
    }, interval);
  
    // Update messages at specific intervals
    props.steps.forEach((message, index) => {
      setTimeout(() => {
        currentMessage.value = message;
        if (index === props.steps.length - 1) {
          isError.value = true;
          clearInterval(progressInterval);
        }
      }, index * stepDuration);
    });
  };
  
  const closeModal = () => {
    window.location = props.action;
  };
  

  onMounted(() => {
    startProcess();
  });
  </script>
  
  <style scoped>
  .transition-all {
    transition: all 0.3s ease-in-out;
  }
  </style>
  