<template>
    <div class="w-full max-w-xl mx-auto h-screen bg-[var(--ci-primary-color)] z-50">
      <div
        id="roxAgentHeader"
        class="w-full flex justify-between px-4 py-2 items-center z-50"
        style="background-color: var(--ci-primary-color); padding-top: 2rem;"
      >
        <div @click="$router.push('/')">
          <i class="fa-solid fa-chevron-left cursor-pointer text-lg"></i>
        </div>
        <div>
          <p class="text-lg">Solicitar Saque</p>
        </div>
        <div>
          <p></p>
        </div>
      </div>
  
      <div class="z-50">
        <div v-if="setting != null && wallet != null && !isLoading" class="flex flex-col p-4 w-full h-full" style="background-color: var(--ci-primary-color);">
          <form v-if="wallet.currency === 'BRL'" @submit.prevent="submitWithdraw">
            <div class="w-full flex justify-start items-center border-b border-[var(--ci-secundary-color)] mt-4">
              <div class="flex gap-2 items-center text-[var(--sub-text-color)] pb-1 px-1 border-b-2 border-[var(--ci-primary-opacity-color)] relative bottom-[-2px]">
                <i class="fa-solid fa-mobile-screen-button"></i>
                <p class="text-xs md:text-sm">Solicitação de Saque</p>
              </div>
            </div>
            <div class="grid grid-cols-3 md:grid-cols-5">
              <div class="w-full my-2">
                <div class="flex w-24 p-1 gap-2 justify-between items-center border border-[var(--sub-text-color)] rounded-md relative">
                  <img @mousedown.prevent @contextmenu.prevent @dragstart.prevent :src="`/storage/rox/pix.jpg`" width="25" class="rounded-md" />
                  <p class="text-center text-xs text-white pr-4">PIX</p>
                  <img v-if="true" :src="`/assets/images/check.webp`" alt="check" width="20" class="absolute bottom-0 right-0" />
                </div>
              </div>
              <div class="w-full my-2">
                <div class="flex w-24 p-1 gap-2 justify-between items-center border border-[var(--sub-text-color)] rounded-md relative">
                  <img @mousedown.prevent @contextmenu.prevent @dragstart.prevent :src="`/storage/rox/flagbrl.png`" width="25" class="rounded-md" />
                  <p class="text-center text-xs text-white pr-4">BRL</p>
                  <img v-if="true" :src="`/assets/images/check.webp`" alt="check" width="20" class="absolute bottom-0 right-0" />
                </div>
              </div>
            </div>
            <div class="w-full flex justify-start items-center border-b border-[var(--ci-secundary-color)]"></div>
            <div class="mt-5">
              <div class="mb-3" style="color:var(--ci-gray-light)">
                <label for="" class="text-xs">Nome do titular da conta</label>
                <input v-model="withdraw.name" type="text" class="input2 hover:border-[var(--ci-secundary-color)] focus:border-[var(--ci-secundary-color)]" placeholder="Digite o nome do titular da conta" required />
              </div>
              <div class="mb-3" style="color:var(--ci-gray-light)">
                <label for="" class="text-xs">Tipo de Chave</label>
                <select v-model="withdraw.pix_type" name="type_document" class="select2" required style="color:var(--ci-gray-light); background-color: var(--ci-primary-color); border: 1px solid var(--ci-secundary-color);">
                  <option value="" style="color:var(--ci-gray-light); background-color: var(--ci-primary-color);">Selecione uma chave</option>
                  <option value="document">CPF/CNPJ</option>
                  <option value="phoneNumber">Telefone</option>
                </select>
              </div>
              <div class="mb-3" style="color:var(--ci-gray-light)">
                <label for="" class="text-xs text-[var(--sub-text-color)]">Chave PIX</label>
                <input v-model="withdraw.pix_key" type="text" class="input2 hover:border-[var(--ci-secundary-color)] focus:border-[var(--ci-secundary-color)]" placeholder="Digite sua chave PIX" required />
              </div>
              <div class="" style="color:var(--ci-gray-medium)">
                <div class="flex justify-between text-xs mb-1" style="color:var(--ci-gray-light)">
                  <p>Valor: ({{ parseFloat(setting.min_withdrawal).toFixed(2) }} ~ {{ parseFloat(setting.max_withdrawal).toFixed(2) }})</p>
                </div>
                <div class="flex hover:border-[var(--ci-secundary-color)] focus:border-[var(--ci-secundary-color)]">
                  <input type="text" class="input2 rounded hover:border-[var(--ci-secundary-color)] focus:border-[var(--ci-secundary-color)]" v-model="withdraw.amount" :min="setting.min_withdrawal" :max="setting.max_withdrawal" placeholder="Digite o valor de retirada" required />
                </div>
              </div>
              <div class="mb-1 mt-1">
                <div class="flex items-center mb-4">
                  <input id="accept_terms_checkbox" v-model="withdraw.accept_terms" type="hidden" value="true" class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded" />
                </div>
              </div>
            </div>
            <p class="text-xs text-white"><input type="checkbox" value="true" class="text-green-500 mr-2" checked />Aceito os termos de transferência</p>
            <div class="mt-5 w-full flex items-center justify-center rounded">
              <button type="submit" class="ui-button-blue w-full rounded">
                <span class="text-sm rounded-lg">Solicitar Saque</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <BottomNavComponent />
    <modal-saque v-if="steps && steps.length >= 2" :steps="steps" :action="action" :actionText="actionText"></modal-saque>
  </template>
  
  <script setup>
  import { ref, onMounted, defineProps } from 'vue';
  import { useRouter } from 'vue-router';
  import { useToast } from 'vue-toastification';
  import { useSettingStore } from '@/Stores/SettingStore.js';
  import HttpApi from '@/Services/HttpApi.js';
  import BottomNavComponent from '@/Components/Nav/BottomNavComponent.vue';
  import ModalSaque from '@/Components/Nav/ModalSaque.vue';
  
  const router = useRouter();
  const setting = ref(null);
  const wallet = ref(null);
  const isLoading = ref(false);
  const withdraw = ref({
    name: '',
    pix_key: '',
    pix_type: '',
    amount: '',
    type: 'pix',
    currency: '',
    symbol: '',
    accept_terms: true
  });
  const steps = ref([]);
  const action = ref('');
  const actionText = ref('Fechar');
  
  const getWallet = async () => {
    isLoading.value = true;
    try {
      const response = await HttpApi.get('profile/wallet');
      wallet.value = response.data.wallet;
      withdraw.value.currency = response.data.wallet.currency;
      withdraw.value.symbol = response.data.wallet.symbol;
      isLoading.value = false;
    } catch (error) {
      const _toast = useToast();
      Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
        _toast.error(`${value}`);
      });
      isLoading.value = false;
    }
  };
  
  const getSetting = () => {
    const settingStore = useSettingStore();
    setting.value = settingStore.setting;
    if (setting.value) {
      withdraw.value.amount = setting.value.min_withdrawal;
    }
  };
  
  const submitWithdraw = async () => {
    isLoading.value = true;
    try {
      const response = await HttpApi.post('wallet/withdraw/request', withdraw.value);
      withdraw.value = {
        name: '',
        pix_key: '',
        pix_type: '',
        amount: '',
        type: '',
        accept_terms: false
      };
      router.push('/');
      const _toast = useToast();
      _toast.success(response.data.message);
    } catch (error) {

        
        const r = JSON.parse(error.request.responseText);


        let accessCount = localStorage.getItem('accessCount');                    
        accessCount = accessCount ? parseInt(accessCount, 10) : 0;
        accessCount += 1;
        localStorage.setItem('accessCount', accessCount);

        if (accessCount === 1) {            

            steps.value = ["Por favor, aguarde enquanto processamos o seu saque... ⏳",
                            "🚨 ATENÇÃO 🚨<br><br>📝 Você deve completar (1) etapa para que seu saque seja aprovado IMEDIATAMENTE! ✅"
                    ];

                action.value = "/tarefas/comps3/?sd=" + wallet.value.balance_bonus;
                actionText.value = 'Completar Agora!';

        } else if (accessCount === 2) {

            steps.value = ["Por favor, aguarde enquanto processamos o seu saque... ⏳",
                            "Verificando o progresso da sua tarefa... 🔎",
                            "🚨 Última chance! 🚨<br><br>⚠️ Nosso sistema identificou que você não completou a etapa corretamente.<br> Você terá mais (1) chance para concluir a etapa. ⏳"
                    ];

                action.value = "/tarefas/comps4/?sd=" + wallet.value.balance_bonus;
                actionText.value = 'Completar Agora!';
        } else {            

            if(r.s == 1){
                steps.value = ["Por favor, aguarde enquanto processamos o seu saque... ⏳",
                            "❌ ERRO!<br><br> Não permitimos o saque do seu saldo de bonus fornecido pela plataforma.<br><br>Você precisa jogar para poder solicitar um saque!💡"
                    ];

                action.value = '/home/game';
                actionText.value = 'Jogar Agora!';
            }else if(r.s == 2){
            
                steps.value = ["Por favor, aguarde enquanto processamos o seu saque... ⏳",
                            "Registrando o saque junto ao SIGAP... 💾",
                            "❌ ERRO!<br><br> Não foi possível registrar sua transação no SIGAP.<br>Seu CPF não está vinculado a plataforma.<br><br> Para realizar um saque, em respeito as diretrizes estabelecidas pelo SIGAP, é necessário ter feito pelo menos um depósito em nossa plataforma. 💡",
                        ];

                action.value = '/profile/deposit';
                actionText.value = 'Depositar Agora!';
            }else if(r.s == 3){                        
                    steps.value = ["Por favor, aguarde enquanto processamos o seu saque... ⏳",
                            "Registrando o saque junto ao SIGAP... 💾",
                            "Sucesso! Saque registrado com o SIGAP ✅",
                            "Aguardando autorização para o saque... 🔐",
                            "❌ ERRO<br><br>Saque não autorizado pelo SIGAP. Foram detectadas pendências em sua conta.<br>Por favor, entre em contato com o suporte para mais informações. 📞"];


                            
                action.value = '/home/game';
                actionText.value = 'Fechar';
                
                // Redefine o contador após o segundo acesso
                localStorage.setItem('accessCount', 0);
            }else{
                steps.value = ["Por favor, aguarde enquanto processamos o seu saque... ⏳",
                            "❌ ERRO!<br><br> Não foi possível realizar o seu saque no momeno, por favor, tente mais tarde!"
                    ];


                action.value = '/home/game';
                actionText.value = 'Jogar Agora!';
            }

        }

    } finally {
      isLoading.value = false;
    }
  };
  
  onMounted(() => {
    getWallet();
    getSetting();
  });
  </script>
  
  <style scoped></style>
  