<template>
  <div class="item-game-container">
    <RouterLink :to="{ name: 'casinoPlayPage', params: { id: game.id, slug: game.game_code }}" class="item-game text-gray-700 w-full h-auto mr-4 cursor-pointer" @mouseover="showIcon = true" @mouseleave="showIcon = false">
      <div class="relative w-full h-full">

        <div v-if="game.is_featured" class="absolute left-0 top-0 p-2 text-white font-bold" style="background: #70cd74;border-radius: 15px 0px 15px 0px;font-size: 10px;">HOT 🔥🔥🔥</div>


        <div v-if="game.is_featured" class="absolute bottom-0 right-0 p-2 text-white font-bold" style="background: #474747;border-radius: 15px 0px 15px 0px;display: flex;align-items: center;gap: 10px;font-size: 10px;">

        <div>Probabilidade:</div>
        <div class="flex" style="gap: 3px;">
            <div v-for="p in probability" :key="p"
            :style="{background: probColor}" style="width: 4px;height: 8px;border-radius: 3px;"></div>
        </div>

        </div>

        <div v-else class="absolute bottom-0 right-0 p-2 text-white font-bold" style="background: #474747;border-radius: 15px 0px 15px 0px;display: flex;align-items: center;gap: 10px;font-size: 10px;">

        <div>Probabilidade:</div>
        <div class="flex" style="gap: 5px;">
            <div v-for="p in 1" :key="p"
            :style="{background: '#dc3545'}" style="width: 4px;height: 8px;border-radius: 3px;"></div>
        </div>

        </div> 

        <img :src="game.distribution === 'kagaming' ? game.cover : `/storage/`+game.cover" alt="" class="w-full" style="border-radius: 15px;">
        <div v-if="showIcon" class="bdc">
          <i class="fa-solid fa-circle-play"></i>
        </div>
      </div>
    </RouterLink>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";

export default {
  props: ['index', 'game'],
  components: { RouterLink },
  data() {
    return {
      showIcon: false,
      probability: 0,
      probColor: '#70cd74'
    }
  },
  methods: {},
  mounted() {

    let time = window.localStorage.getItem('lastProbabilityTime');
    let gameProbability = window.localStorage.getItem('gameProbability_'+this.game.game_code);

      if(time && (time+(5*60000)) < Date.now()){
             time = false;
      }

      if(!time || !gameProbability){

        window.localStorage.setItem('lastProbabilityTime', Date.now());

        const prob = Math.floor(Math.random() * 5) + 1;

        window.localStorage.setItem('gameProbability_'+this.game.game_code, prob);
        this.probability = prob;


      }else{
        this.probability = parseInt(window.localStorage.getItem('gameProbability_'+this.game.game_code));
      }
      if(this.probability > 4){
            this.probColor = '#28a745';
        }else if(this.probability <=3 && this.probability > 1){
            this.probColor = '#ffc107';
        }else if(this.probability <= 1){
            this.probColor = '#dc3545';
        }

  }
};
</script>

<style scoped>
.item-game-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.item-game {
  position: relative;
  border-radius: 15px !important;
  background-color: var(--ci-primary-color) !important;
}

.bdc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45); /* Fundo semitransparente */
  border-radius: 15px; /* Mesma borda arredondada que o card */
  display: flex;
  align-items: center;
  justify-content: center;
}

.bdc i {
  font-size: 3rem; /* Ajuste o tamanho conforme necessário */
  color: var(--ci-primary-color);
  z-index: 1; /* Garante que o ícone esteja acima do backdrop */
}

.item-game img {
  display: block;
  border-radius: 15px;
}
</style>
